@import "../assets/fonts/Dana/Webfonts/css/fontiran.css", "src/styles/references/index";

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
* {
    font-family: dana, sans-serif !important;
}

.text-center {
    text-align: center !important;
    margin: 0 auto;
}
input::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
    font-size: 12px;
}
:where(.css-dev-only-do-not-override-12upa3x).ant-divider-horizontal {
    margin: 12px 0px !important;
}

.ant-tooltip-inner {
    text-align: right;
    direction: rtl;
    padding: 14px !important;
}

.ant-tour-inner {
    background: linear-gradient(90deg, #e5042d 0%, #990022 100%) !important;
}

.ant-tour-content {
    max-width: 232px !important;

    .ant-tour-description {
        text-align: right;
        direction: rtl;
    }
    .ant-tour-footer {
        direction: rtl !important;
    }
}
:where(.css-dev-only-do-not-override-12upa3x).ant-tour.ant-tour-primary {
    --antd-arrow-background-color: linear-gradient(90deg, #e5042d 0%, #990022 100%) !important;
}

.ant-empty-image svg,
.ant-empty-image ellipse {
    fill: #151515 !important;
}

.ant-empty-description p {
    color: #ffffff;
    opacity: 0.5;
    font-size: 10px;
    direction: rtl;
}

.ns-drawer {
    .ns-button {
        width: 100%;
        max-width: 327px !important;
        button {
            height: 48px !important;
            width: 100%;
            font-size: 12px;
            font-weight: 500;
        }
    }
    .ns-input {
        margin-bottom: 16px;
        .input-field {
            border: unset !important;
            border-radius: 12px;
            background: rgba(27, 27, 27, 1);
            width: 100%;
            padding-right: 16px;
            input {
                color: #ffffff;
            }
        }
        .input-icon {
            display: flex;
            align-items: center;
            opacity: 1;
            right: 0 !important;
            left: 8%;
            column-gap: 8px;
            span {
                font-size: 11px;
                font-weight: 500;
                color: rgba(7, 136, 255, 1);
            }
        }
    }
}
