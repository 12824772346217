@import "src/styles/references/index.scss";

.ns-learn {
    text-align: right;
    direction: rtl;

    h2 {
        font-weight: bold;
        font-size: 16px;
        color: #f7f7f7 !important;
        margin: 32px 0px;
    }
    p {
        color: #f7f7f7;
    }
    img {
        margin: 24px auto;
        border-radius: 16px;
    }
}
