@import "src/styles/references/index.scss";

.ns-swap {
    .fixed-swap-icon {
        left: 45%;
        top: 161px;
        position: absolute;
        cursor: pointer;
    }
    .source-wrapper {
        position: relative;
    }
    .swap-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
        row-gap: 16px;
        .wrapper {
            background: rgb(27, 27, 27);
            border-radius: 16px;

            .wrapper-body {
                flex-direction: row-reverse;
                color: white;
                display: flex;
                min-height: 82px;
                justify-content: space-between;
                align-content: center;
                width: 100%;
                max-width: 327px !important;
                margin: 0 auto;
                .right-side {
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    padding: 16px;
                    span:nth-child(1) {
                        font-weight: 500;
                        font-size: 10px;
                        margin-bottom: 12px;
                    }
                    span:nth-child(2) {
                        font-weight: 500;
                        font-size: 16px;
                    }

                    .balance {
                        direction: rtl;
                        display: flex;
                        flex-direction: revert-layer;
                        align-items: baseline;
                        column-gap: 4px;
                    }

                    .ns-input {
                        max-width: 182px !important;

                        .input-field {
                            border: unset !important;
                        }
                        input {
                            height: 28px !important;
                        }
                    }
                }
                .left-side {
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    direction: rtl;
                    padding: 16px;
                    span:nth-child(1) {
                        display: flex;
                        align-items: center;
                        column-gap: 4px;
                        font-size: 10px;
                        font-weight: 500;
                        span {
                            font-size: 10px;
                            font-weight: 500;
                        }

                        span.balance {
                            margin-top: 3px !important;
                        }
                    }
                    span:nth-child(2) {
                        display: flex;
                        column-gap: 6px;
                        align-content: center;
                        align-items: center;
                        img {
                            margin-bottom: 3px;
                        }
                    }

                    span.select {
                        margin-top: 6px;
                        cursor: pointer;

                        span {
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }
                }
                .right-side span {
                    text-align: right;
                }
                .left-side span {
                    text-align: right;
                }
                img {
                    height: 24px;
                }
            }
            .commission-wrapper {
                border-top: 1px solid rgba(54, 54, 54, 0.35);
                display: flex;
                flex-direction: row-reverse;
                color: white;
                display: flex;
                min-height: 32px;
                justify-content: space-between;
                align-content: center;
                width: 100%;
                max-width: 327px !important;
                margin: 0 auto;

                .right-side {
                    display: flex;
                    direction: rtl;
                    column-gap: 4px;
                    align-items: center;
                    span:nth-child(2) {
                        direction: ltr;
                    }
                }
                .left-side {
                    display: flex;
                    direction: ltr;
                    column-gap: 4px;
                    align-items: center;
                }
                span {
                    font-size: 10px;
                    color: rgba(153, 153, 153, 1);
                    width: 300;
                }
            }
        }

        .ns-button {
            width: 100%;
            max-width: 327px !important;
            button {
                height: 48px !important;
                width: 100%;
            }
        }
    }
}

.exchange-selection {
    .headline {
        margin: 24px auto;
        width: 100%;
        width: 28ch;

        h3 {
            font-size: 14px;
            font-weight: 500;
            color: #ffffff;
            margin-bottom: 8px;
        }
        p {
            font-size: 10px;
            font-weight: 400;
            text-align: center;
            direction: rtl;
            color: rgba(255, 255, 255, 0.5);
        }
    }
    .ns-button {
        margin-bottom: 32px;
        max-width: 327px;
        width: 100%;
        height: 48px;
        button {
            height: 48px;

            font-size: 14px;
            font-weight: 500;
        }
    }
    .connected-exchanges {
        .grid-row {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 16px;
            text-align: center;
            flex-direction: row-reverse;

            .disabled {
                opacity: 0.3 !important;
            }
        }
        img {
            height: 56px;
            width: 56px;
            border-radius: 16px;
        }
        span {
            font-weight: 300;
            font-size: 10px;
            margin-top: 6px;
            color: rgba(250, 250, 250, 0.8);
            text-align: center;
        }
    }
}
