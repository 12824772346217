@import "src/styles/references/index";

.ns-back-button {
    display: flex;
    align-items: center;
    justify-content: left;
    column-gap: 6px;
    width: max-content;
    cursor: pointer;
    span {
        color: #ffffff;
        opacity: 0.6;
        font-size: 12px;
    }
    svg {
        fill: #ffffff;
        opacity: 0.6;
        font-size: 12px;
    }
}
