@import "src/styles/references/index.scss";

.ns-container {
    display: flex;
    // background: $bg;
    width: 100%;
    min-height: 100vh;
    height: 450px;
    overflow-y: auto;
    margin: auto;
    column-gap: 32px;
    scrollbar-width: none;
    margin-bottom: 124px;
    ::-webkit-scrollbar {
        width: 0px !important;
    }

    ::-webkit-scrollbar-thumb {
        background: $scroll-thumb-bg;
    }

    ::-webkit-scrollbar-track {
        background: $white;
    }

    .swap-boxes {
        display: flex;
        flex-wrap: wrap;
        column-gap: 8px;
        row-gap: 8px;
        direction: rtl;

        .ns-swapbox:last-child {
            max-width: unset !important;
            width: 100% !important;
            flex: unset !important; /* This makes each item take up to 50% of the container's width */
        }
    }
    .learn-row {
        display: flex;
        column-gap: 8px;
        align-items: center;
        color: white !important;
        direction: rtl;
        margin-top: 24px;
        cursor: pointer;
        span,
        svg {
            font-size: 12px;
        }

        background: rgba(27, 27, 27, 1);

        border-radius: 12px;
        display: flex;
        flex-direction: row;
        column-gap: 8px;
        align-items: center;
        justify-content: space-between;
        padding: 16px 8px;
        direction: ltr;

        box-sizing: border-box;
        padding: 10px; /* Optional: Add some padding for spacing */
        text-align: center; /* Optional: Center text within items */
        img {
            height: 32px;
            width: 32px;
        }
        span {
            color: white;
            b {
                background-clip: text;
                color: transparent;
            }
        }
    }

    .main-banner {
        width: 95%;
        margin-top: 32px !important;
        cursor: pointer;
    }
}
