@import "src/styles/references/index.scss";

.rws-header {
    text-align: center !important;
    font-weight: initial !important;
    font-size: 18px !important;
}
.ns-deposit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .skeleton.qr-code-wallet {
        width: 241px !important;
        padding: 8px;
    }
    .main-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        row-gap: 24px;
        .coin-info {
            text-align: center;

            h2 {
                color: #ffffff;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 16px;
                margin-top: 32px;
            }
            p {
                color: #ffffff;
                max-width: 40ch;
                font-size: 14px;
                font-weight: 400;
                opacity: 0.5;
                direction: rtl;
            }
        }
        .deposit-address-wallet {
            display: flex;
            cursor: pointer;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: #1b1b1b !important;
            border-radius: 16px;
            width: 241px !important;
            padding: 8px;

            #react-qrcode-logo {
                width: 209px !important;
                border-radius: 12px;
                height: 209px !important;
                margin: 16px auto;
            }
            p {
                text-align: center;
                word-break: break-all;
                color: #ffffff;
                font-size: 12px;
                font-weight: 400;
                max-width: 25ch;
                font-family: monospace !important;
                padding-bottom: 24px;
            }
        }
        .more-info {
            p {
                opacity: 0.4;
                text-align: center;
                direction: rtl;
                max-width: 38ch;
                font-size: 12px;
                color: #ffffff;

                span {
                    opacity: 1;
                    font-weight: 900;
                }
            }
        }
        p {
            color: #ffffff;
            max-width: 40ch;
            font-size: 10px;
            font-weight: 400;
            opacity: 0.5;
            direction: rtl;
        }
        .shares {
            display: flex;
            align-items: center;
            justify-content: space-around;
            .copy-button {
                cursor: pointer;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;

                border-radius: 50%;
                padding: 16px;
                .ns-button {
                    font-size: 16px;

                    button {
                        padding: 8px;
                        font-size: 16px !important;

                        span {
                            font-size: 16px;

                            margin: unset !important;
                        }
                    }
                }
                .ns-button .dark {
                    direction: rtl;
                    column-gap: 4px;
                }
            }
            .share-button {
                cursor: pointer;

                border-radius: 50%;
                padding: 16px;

                .ns-button .dark {
                    direction: rtl;
                }
                .ns-button {
                    button {
                        font-size: 16px !important;
                        padding: 8px;
                        span {
                            font-size: 16px !important;
                        }
                    }
                }
            }
        }
    }
}
