@import "src/styles/references/index.scss";

.ns-credit-card {
    width: max-content;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    height: max-content;
    position: relative;
    width: 100%;
    //height: 148px;
    //border: 2px solid #000000;
    margin: 0 auto;
    // box-shadow:
    //     rgba(17, 17, 26, 0.1) 0px 8px 24px,
    //     rgba(17, 17, 26, 0.1) 0px 16px 56px,
    //     rgba(17, 17, 26, 0.1) 0px 24px 80px;

    display: flex;
    align-items: end;
    justify-content: end;

    .main-banner {
        width: 100%;
        margin-top: 32px !important;
        cursor: pointer;
    }
    .wallet-options {
        display: flex;
        justify-content: center;
        align-content: center;
        column-gap: 48px;
        margin-top: 16px;
        margin: 0 auto;
        margin-top: 16px !important;
        svg {
            fill: #f7f7f7 !important;
        }
    }
    img.tokens {
        position: absolute;
        left: 0%;
        top: -30px;
        height: 152px;
    }
    span svg {
        fill: black;
        margin-bottom: 54px;

        :hover {
            cursor: pointer;
        }
    }
    svg {
        fill: #151515;
    }
    h6 {
        font-size: 10px;
        font-weight: 300;
        color: rgba(153, 153, 153, 1);
        margin-bottom: -8px;
    }
    .balance {
        direction: rtl;
        font-weight: 700;
        font-size: 36px;
        font-family: "Poppins", sans-serif !important;
        margin: 32px auto;
        margin-bottom: 8px !important;
        color: #ffffff;
        span {
            font-size: 24px;
            font-weight: 300 !important;
            font-weight: bolder;
        }
    }
    &.rounded {
        border-radius: 16px;
    }

    &:hover {
        cursor: pointer;
        svg {
            fill: #f7f7f7;
        }
        span {
            color: #f7f7f7;
        }
    }
}
