@import "src/styles/references/index.scss";

.ns-sell-pm {
    .back-line {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;
        column-gap: 8px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            color: #ffffff;
            opacity: 0.6;
            margin-bottom: 3px;
        }
    }
    .coin-info {
        text-align: center;
        margin-bottom: 32px;

        h2 {
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 16px;
            margin-top: 32px;
        }
        p {
            color: #ffffff;
            max-width: 40ch;
            font-size: 10px;
            font-weight: 400;
            opacity: 0.5;
            direction: rtl;
            text-align: center;
            width: 100%;
            margin: 0 auto;
        }
    }
    .voucher-infos {
        width: 100%;
        .ns-input {
            margin-bottom: 16px;
            .input-field {
                border: unset !important;
                border-radius: 12px;
                background: rgba(27, 27, 27, 1);
                width: 100%;
                padding-right: 16px;
                input {
                    color: #ffffff;
                }
            }
            .input-icon {
                display: flex;
                align-items: center;
                opacity: 1;
                right: 0 !important;
                left: 8%;
                column-gap: 8px;
                span {
                    font-size: 11px;
                    font-weight: 500;
                    color: rgba(7, 136, 255, 1);
                }
            }
        }
        .add-wallet-address {
            display: flex;
            flex-direction: row-reverse;
            column-gap: 8px;
            align-items: center;
            margin-bottom: 32px;

            span {
                color: #ffffff;
                opacity: 0.6;
                font-weight: 300;
                font-size: 12px;
            }
            &.visible {
                span {
                    opacity: 1 !important;
                }
                svg {
                    path {
                        stroke: #ffff !important;
                    }
                    rect {
                        opacity: 1 !important;
                        fill: linear-gradient(90deg, #e5042d 0%, #990022 100%);
                    }
                }
            }
        }
    }
    .mine-selected-addresses {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .addresses-list {
            width: 100%;
            max-height: calc(2 * 68px); /* Show only 2 rows */
            overflow-y: scroll; /* Enable vertical scrolling */
        }
        h4 {
            font-size: 16px;
            font-weight: 700;
            color: #ffffff;
            text-align: right;
            width: 100%;
            margin-bottom: 16px;
        }
        .recent-address-wallet {
            display: flex;
            width: 100%;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: space-between;
            background: rgba(27, 27, 27, 1);
            height: 63px;
            margin-bottom: 8px;
            padding: 16px;
            border-radius: 12px;
            .right-side {
                display: flex;
                flex-direction: row-reverse;
                text-align: right;
                column-gap: 6px;
                .wallet-info {
                    display: flex;
                    flex-direction: column;
                    row-gap: 4px;
                    margin-bottom: 3px;

                    span:first-child {
                        font-weight: 500;
                        font-size: 12px;
                        color: #ffffff;
                    }
                    span:nth-child(2) {
                        font-weight: 300;
                        font-size: 10px;
                        color: rgba(255, 255, 255, 0.5);
                    }
                }
            }
            .left-side {
                display: flex;
                flex-direction: column;
                justify-content: center;
                row-gap: 8px;
                span {
                    font-size: 10px;
                    font-weight: 300;
                    color: rgba(255, 255, 255, 0.5);
                }
            }
            img {
                height: 32px;
                width: 32px;
                border-radius: 28px;
            }
        }
    }
}
